import React from "react"
import styled from "styled-components"

const Text = styled.div`
  width: 50%;

  //ensuring that table content width doesn't exceed the max width
  .table-container {
    max-width: 100%;
    word-wrap: anywhere;
  }

  //button design
  button {
    background: transparent;
    border: none;
    color: #007de6;
    cursor: pointer;

    &:hover {
      color: #00aeff;
    }
  }

  //links desgins
  a {
    color: #007de6;
    &:hover {
      color: #00aeff;
    }
  }
  ul {
    margin-top: 48px;
  }
`

function TextContainer({ children }) {
  return <Text>{children}</Text>
}
export default TextContainer
