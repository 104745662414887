import React from "react"
import styled from "styled-components"

const Header2 = styled.h2`
  font-family: "Work Sans", sans-serif;
  background: #fbfbfb;
  width: 100%;
  height: max-content;
  border-bottom: 1px solid #e1e1e1;
  text-align: center;
  padding: 70px;
  font-family: "Work Sans", sans-serif;
  font-size: 58px;
  margin-bottom: 100px;
  line-height: 58px;

  @media (max-width: 769px) {
    font-size: 32px;
    line-height: 36px;
  }
`

function Title({ children }) {
  return <Header2>{children}</Header2>
}

export default Title
