import React from "react"
import styled from "styled-components"

const Paragraph = styled.p`
  width: 100%;
  text-align: left;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  line-height: 27px;
  :last-of-type {
    margin-bottom: 5rem;
  }
`

function Paragraphs({ children }) {
  return <Paragraph>{children}</Paragraph>
}

export default Paragraphs
