import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: content;
  width: 100%;
`

function TermsContainer({ children }) {
  return <Container>{children}</Container>
}

export default TermsContainer
