import React from "react"
import styled from "styled-components"

const Header3 = styled.h3`
  width: 100%;
  text-align: left;
  font-family: "Work Sans", sans-serif;
  font-size: ${({ size }) =>
    size === undefined
      ? `24px`
      : size === "medium"
      ? "21px"
      : size === "small"
      ? "18px"
      : size};
  line-height: ${({ height }) =>
    height === undefined
      ? "40px"
      : height === "medium"
      ? "36px"
      : height === "small"
      ? "32px"
      : height};
`

function Subtitles({ size, height, children }) {
  return (
    <Header3 size={size} height={height}>
      {children}
    </Header3>
  )
}

export default Subtitles
