import React from "react"
import Seo from "../components/seo"
import Contacts from "../components/contacts"
import Container from "../components/shared/policies/termsContainer"
import Text from "../components/shared/policies/textContainer"
import Title from "../components/shared/policies/title"
import Subtitles from "../components/shared/policies/subtitles"
import Paragraphs from "../components/shared/policies/paragraphs"
import { Link } from "gatsby"
import NewFooter from "../components/shared/NewFooter"

const CheckoutPage = () => {
  return (
    <>
      <Container>
        <Seo title="Terms of Service" />
        {/* <TermsOfUse></TermsOfUse> */}
        <Title>PDF Pro TERMS OF SERVICE</Title>
        <Text>
          <Paragraphs>
            <i>Last update: October 17th, 2021</i>
          </Paragraphs>
          <Paragraphs>
            These terms of service (hereinafter referred to as the “
            <strong>ToS</strong>”) constitute a legal and binding agreement
            between PDF Pro Software Ltd., with registered office at 1383 W 8th
            Ave, Vancouver, BC V6H 3V9, Canada, business registration no.
            700468291BC0001, including its subsidiaries, affiliates and
            contractors acting on its behalf (hereinafter collectively referred
            to as “<strong>PDF Pro</strong>”, “<strong>Us</strong>”, “
            <strong>We</strong>”, or “<strong>Our</strong>”) and any person or
            entity and its heirs, agents, successors and assigns that access Our
            PDF Pro Software and/or any related Services (hereinafter singularly
            and collectively referred to as “<strong>You</strong>” or “
            <strong>Your</strong>”).
          </Paragraphs>
          <Paragraphs>
            <strong>
              These ToS include and incorporate by reference the PDF Pro EULA
              (available at <Link to="/licensing">EULA</Link> ) the PDF Pro
              Privacy Policy (available at{" "}
              <Link to="/privacy">Privacy Policy</Link> ) and the PDF Pro Data
              Processing Agreement (available at{" "}
              <Link to="/dpa">Data Processing Agreement</Link> ).
            </strong>
          </Paragraphs>
          <Paragraphs>
            {" "}
            <strong>
              IMPORTANT NOTICE: Your continued use of, and access to, Our
              Services constitutes Your expressed acceptance of, and agreement
              to be bound, by these ToS, as in force from time to time.
              Therefore, You understand and acknowledge that by accessing or
              using the PDF Pro Software and/or any related Services You agree
              to be bound by the terms of these ToS. If You do not accept such
              terms in their entirety, You must not access or use the PDF Pro
              Software and/or any related Services. If You agree to these ToS on
              behalf of an entity, or in connection with providing or receiving
              services on behalf of an entity, You represent and warrant that
              You have the authority to bind that entity to these ToS. In such
              an event, “You” and “Your” will refer and apply to that entity or
              agency.
            </strong>
          </Paragraphs>
          <Subtitles>1.Definitions</Subtitles>
          <Paragraphs>
            The following capitalized terms shall have the meanings set forth
            below, provided that the singular includes the plural and
            vice-versa, and any gender shall include the other genders:
          </Paragraphs>
          <Paragraphs>
            <strong> 1.1 “Authorized Users”</strong> means, singularly and
            collectively, in case of Multi-User Account, each and all the users
            that You authorized to access and use the PDF Pro Software and/or
            the related Services, according to the number of Authorized Users
            allowed by the applicable Subscription Plan.
          </Paragraphs>
          <Paragraphs>
            <strong>1.2 “Data”</strong> means any information, text, message,
            software, picture, sound, video, music and any library, data,
            content, or material transmitted or conveyed through the PDF Pro
            Software and/or the related Services.
          </Paragraphs>
          <Paragraphs>
            <strong>1.3 “Effective Date”</strong> means any of the following
            moments, whichever occurs first: (<strong>i</strong>) Your express
            acceptance of these ToS; (<strong>ii</strong>) Your download and/or
            installation of the PDF Pro Software; (<strong>iii</strong>) Your
            registration of an account on the PDF Pro website platform at
            <Link to="/register">Register</Link>; (<strong>iv</strong>) Your
            acceptance of any valid communication with Us or any of Our
            authorized representative that refers to these ToS.
          </Paragraphs>
          <Paragraphs>
            <strong>1.4 “Force Majeure”</strong> means any event or
            circumstance: (<strong>i</strong>) beyond the affected Party’s
            reasonable control; (<strong>ii</strong>) that it could not
            reasonably have been foreseen at the time of the Effective Date; (
            <strong>iii</strong>) whose effects could not reasonably have been
            avoided or overcome by the affected Party. At the said conditions,
            “Force Majeure” include: war, hostilities, invasion, act of foreign
            enemies, extensive military mobilization, civil war, riot, rebellion
            and revolution, military or usurped power, insurrection, act of
            terrorism, sabotage or piracy, currency and trade restriction,
            embargo, sanction, act of authority whether lawful or unlawful,
            compliance with any law or governmental order, expropriation,
            seizure of works, requisition, nationalization, plague, pandemic,
            epidemic, natural disaster or extreme natural event, explosion,
            fire, destruction of equipment, prolonged break-down of transport,
            telecommunication, information system or energy, general labor
            disturbance (such as boycott, strike and lock-out, go-slow,
            occupation of factories and premises).
          </Paragraphs>
          <Paragraphs>
            <strong>1.5 “Intellectual Property Rights”</strong> or{" "}
            <strong>“IP Rights”</strong>
            means all intellectual property and other similar proprietary rights
            in any jurisdiction, whether owned or held for use under license,
            whether registered or unregistered, including such rights in and to:
            trademarks, logos, trade names, patents and patent applications,
            utility models, inventions, discoveries, writings and other works of
            authorship, trade secrets, information that is not generally known
            to the public or that constitutes a trade secret under any
            applicable Law, Software (including, without limitation, data files,
            source code, object code, application programming interfaces,
            databases and other software-related specifications and
            documentation), advertising and promotional materials, as well as
            claims, causes of action and defenses relating to the enforcement of
            any of the foregoing. In each case above, “IP Rights” include also
            any registrations of, applications to register, and renewals and
            extensions of, any of the foregoing before or by any governmental
            authority in any jurisdiction.
          </Paragraphs>
          <Paragraphs>
            <strong>1.6 “Law”</strong> means, collectively and indistinctly, any
            applicable local, state, national, and international laws, by-laws,
            rules, regulation, circulars, notifications, ordinances, protocols,
            codes, guidelines, policies, notices, directions, orders, decrees,
            judgments of courts, or other requirements of any governmental
            authority.
          </Paragraphs>
          <Paragraphs>
            <strong>1.7 “Multi-User Account”</strong> means an account that
            allows You to authorize Authorized Users to access the PDF Pro
            Software and the related Services, within the license limits set out
            in the applicable Subscription Plan.
          </Paragraphs>
          <Paragraphs>
            <strong>1.8 “Party”</strong> means either You or Us, while
            <strong>“Parties”</strong> means, collectively, both You and Us.
          </Paragraphs>
          <Paragraphs>
            <strong>1.9 “PDF Pro EULA”</strong> means, collectively, the terms
            and conditions of the “PDF Pro End User License Agreement” available
            at <Link to="/licensing">EULA</Link> , which shall be incorporated
            herein by reference, that regulates the license of use of the PDF
            Pro Software.
          </Paragraphs>
          <Paragraphs>
            {" "}
            <strong> 1.10 “PDF Pro Software”</strong> means, collectively and
            indistinctly, Our “PDF Pro” proprietary Software and any Third-Party
            Software integrated thereto, which is made available to You
            according to a Subscription Plan purchased and is installed and
            executed on a local computing machine or device.
          </Paragraphs>
          <Paragraphs>
            <strong>1.11 “PDF Pro Terms of Service”</strong> or{" "}
            <strong>“ToS”</strong> means, collectively, the terms and conditions
            herein and any further amendment thereof, as available from time to
            time on Our website at <Link to="/terms">Terms of Service</Link>.
          </Paragraphs>
          <Paragraphs>
            <strong>1.12 “Personal Data”</strong> means any Data referred to an
            identified or identifiable natural person.
          </Paragraphs>
          <Paragraphs>
            <strong> 1.13 “Personal Data Protection Laws”</strong> mean any
            applicable Law regarding the protection of Personal Data, including
            but not limited to the European General Data Protection Regulation
            no. 2016/679.
          </Paragraphs>
          <Paragraphs>
            <strong> 1.14 “Software”</strong> means, collectively and
            indistinctly, any software, including, without limitation, computer
            software, documentation (including, without limitation, standard
            product documentation, user and technical documentation, further
            documents that describe the standard functionalities thereof, etc.),
            tool, application, application programming interface (API),
            development libraries, user interfaces, component, user interface,
            appearance (including, without limitation, images, designs, fonts,
            etc.), code (including, without limitation, assemblers, applets,
            compilers, source code, source code listings, object code, plug-in,
            etc.), as well as any fixes, patches, updates or upgrades to such
            items.
          </Paragraphs>
          <Paragraphs>
            <strong>1.15 “Services”</strong> means, collectively and
            indistinctly, the services (including, without limitation, SaaS,
            internet-based service, etc.) made available to You according to
            these ToS and the Subscription Plan that You purchased. To such
            purpose, “Services” include also the access to the PDF Pro website
            platform available at <Link to="/features">Features</Link> and any
            associated feature or service, as well as any Software or Data to
            which is provided access to You as part of the PDF Pro Software
            and/or the same Services.
          </Paragraphs>
          <Paragraphs>
            <strong>1.16 “Subscription Plan”</strong> means the subscription
            plan purchased by You among those specified at{" "}
            <Link to="/checkout">Checkout</Link>. “Subscription Plan” includes
            also any related purchasing order and any special condition therein
            agreed upon between the Parties.
          </Paragraphs>
          <Paragraphs>
            <strong>1.17 “Third-Party Software”</strong> means, collectively and
            indistinctly, any Software developed, distributed or sold by an
            entity other than the Parties.
          </Paragraphs>
          <Subtitles>2.OVERVIEW</Subtitles>
          <Paragraphs>
            <strong>2.1 Services</strong>. Our Services provide access to the
            PDF Pro Software and the related Services for editing files in PDF
            format with the features and specifications specified in the
            applicable Subscription Plan.{" "}
            <strong>
              The license of use the PDF Pro Software to which is provided
              access as part of the Services is also subject to the terms and
              conditions of the PDF Pro EULA, which shall be incorporated herein
              by reference.
            </strong>
          </Paragraphs>
          <Paragraphs>
            <strong>2.2 Rights granted</strong>. Subject to these ToS, You are
            granted a limited, non-assignable, non-sublicensable,
            non-transferable and non-exclusive license to access and use the PDF
            Pro Software and the related Services, within the limits specified
            in the applicable Subscription Plan purchased.
          </Paragraphs>
          <Paragraphs>
            <strong>2.3 Age limit</strong>. You represent and warrant that You
            are the minimum age in order for Us to lawfully provide the Services
            to You (including processing Your Personal Data) without parental
            consent.
          </Paragraphs>
          <Paragraphs>
            <strong>2.4 Scope</strong> . You expressly agree that the PDF Pro
            Software and the related Services shall be accessed and used only
            within the limit set out in these ToS. In particular, without
            limiting the foregoing, You shall not – unless otherwise authorized
            in written by Us – sell, lease, re-distribute, provide managed
            service, or otherwise provide or make available the PDF Pro Software
            and/or the related Services to any third party.
          </Paragraphs>
          <Paragraphs>
            <strong>2.5 Language</strong>. The PDF Pro Software and the
            Services, as well as the related official documentation are all
            provided by Us in English only. Machine-translation in other
            languages (such as, French, Spanish, German, Italian, Portuguese,
            Dutch and Swedish) is provided for convenience only and Sect. 13.5)
            shall apply.
          </Paragraphs>
          <Paragraphs>
            <strong>2.6 Reservation of rights</strong>. We expressly reserve any
            and all rights not expressly granted to You in these ToS.
          </Paragraphs>
          <Subtitles>3.ACCOUNT REGISTRATION AND ACCESS</Subtitles>
          <Paragraphs>
            <strong>3.1 Account registration</strong>. When You subscribe to a
            Subscription Plan, You will be assigned a username and a password
            for an account, from which You can manage Your subscription
            (including, without limitation, manage Your Personal Data and other
            account Data, upgrade/downgrade a Subscription Plan, add/remove
            Authorized users if You purchased a Multi-User Account, etc.). You
            are entirely responsible for safeguarding and maintaining the
            confidentiality of Your account and, to such purpose, You
            acknowledge and accept that We will assume that any person accessing
            and using the PDF Pro Software and/or the related Services with Your
            login information, is You.
          </Paragraphs>
          <Paragraphs>
            <strong>3.2 Authorized Users</strong>. In case You purchased a
            Subscription Plan with a Multi-User Account, You may allow
            Authorized Users to access the PDF Pro Software and the related
            Services within the limited number of licenses purchased with such
            Subscription Plan, provided in any case that such access shall be
            for the Authorized Users’ sole benefit and in full compliance with
            these ToS. To such purpose, You shall: (<strong>i</strong>) identify
            the Authorized Users and assign, revoke or reassign them their
            account login information (i.e., username and password) for the
            access to the PDF Pro Software and the Services; (
            <strong>ii</strong>) control against any unauthorized access to the
            PDF Pro Software and/or to the Services by the Authorized Users; (
            <strong>iii</strong>) be responsible for the Authorized Users’
            compliance with these ToS as well as for all activities that occur
            under their account or as a result of any Authorized Users’ access
            to the PDF Pro Software and to the Services.
          </Paragraphs>
          <Subtitles>4.SUBSCRIPTION PLAN</Subtitles>
          <Paragraphs>
            <strong>4.1 Subscription Plan</strong>. In order to access and use
            the PDF Pro Software and the related Services, You are requested to
            subscribe a Subscription Plan, choosing among various plans that
            differ in terms of features, number of accessible Services and
            prices. In particular: (<strong>i</strong>) the “PDF Pro Lite”
            Subscription Plan includes basic PDF files editing functionalities
            such as “Read”, “Create”, “Annotate PDFs”, “Merge”, “Split”, “Sign”,
            add passwords, scan to PDF, etc.; (<strong>ii</strong>) the “PDF
            Pro” Subscription Plan includes the features from the “PDF Pro Lite”
            plan, plus text and image editing features; (<strong>iii</strong>)
            the “PDF Pro + OCR” Subscription Plan includes all the features from
            the “PDF Pro” plan, plus optical character recognition. The full
            list of the features of each Subscription Plan is available at
            <Link to="/checkout">Checkout</Link>.
          </Paragraphs>
          <Paragraphs>
            <strong>4.2 Trial Period</strong>. The trial period is designed to
            enable You to evaluate – for free and for a fifteen-days period as
            of the corresponding Effective Date – all or part of the PDF Pro
            Software and the related Services before taking out a paid
            Subscription Plan. In case You apply for a free trial by registering
            an account, We will make the required PDF Pro Software and the
            related Services available to You for the earlier between fifteen
            (15) days from the trial account registration or the Effective Date
            of a Subscription Plan purchased. In case the 15-days trial period
            expires without the purchase of a Subscription Plan, access to the
            evaluated PDF Pro Software and the Services will be prevented by Us;
            to such purpose, You expressly acknowledge and accept that We are
            not be liable towards You, any Authorized User or any other third
            party for any Data and/or customization that might be lost due to
            the said limited access or fruition of the PDF Pro Software and/or
            the related Services.
          </Paragraphs>
          <Paragraphs>
            <strong>4.3 Subscription Plan update/downgrade</strong>. Changes of
            Subscription Plans are allowed through Your account and are
            immediately effective upon payment only in case such change imply an
            upgrade of the then-current Subscription Plan. Any change implying a
            downgrade of the then-current Subscription Plan shall be effective
            only after the expiry of the latter.
          </Paragraphs>
          <Subtitles>5.FEES AND BILLING TERMS</Subtitles>
          <Paragraphs>
            <strong>5.1 Fees</strong>. When You subscribe to a Subscription
            Plan, You agree to pay any and all related applicable fees according
            to the specific terms thereof. Unless otherwise provided, all prices
            are stated in US Dollars (USD). The prices are exclusive of all
            taxes, levies, or duties imposed by tax authorities, for which You
            shall be responsible for the related payment.
          </Paragraphs>
          <Paragraphs>
            <strong>5.2 Billing</strong>. Subscription fees are invoiced in
            advance, according to the periodicity set out in the purchased
            Subscription Plan (i.e., monthly or yearly, as the case may be), and
            are paid by You via Our official reseller FastSpring (
            <a
              href="https://www.fastspring.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.fastspring.com
            </a>
            ). To such purpose, You expressly agree: (<strong>i</strong>) to
            provide the said reseller with valid, up-to-date and complete
            debit/credit card, contact and billing details associated with You;
            (<strong>ii</strong>) to authorize the reseller to bill such
            debit/credit card on the corresponding Effective Date (and/or
            renewals ones, as the case may be) for the due Subscription Plan’s
            fees; (<strong>iii</strong>) to comply with the terms and conditions
            set out by Our reseller with reference to any transactions entered
            into its platform; (<strong>iv</strong>) that We may at Our option –
            if, for any reason, Your credit/debit card company refuses to pay
            the amount billed for the purchased Subscription Plan – suspend or
            terminate Your access to the features of the Subscription Plan, and
            require You to pay the overdue amount by other means acceptable to
            Us, plus an interest rate of one and one-half percent (1.5%) per
            month or the highest applicable rate provided by the Law, from the
            due date until such amount is paid.
          </Paragraphs>
          <Paragraphs>
            <strong>5.3 Prices change</strong>. At any time, We are entitled to
            review the fees of any Subscription Plan and, to such purpose, You
            will be informed at least three (3) months before the change takes
            effect. In any case, the then-current Subscription Plan’s price
            shall not be affected by the change, as the new fees shall be
            applied to the subsequent Subscription Plan’s periods.
          </Paragraphs>
          <Paragraphs>
            <strong>5.4 Refunds</strong>. Refunds of any Subscription Plan’s fee
            can be required within thirty (30) days from the Effective Date of
            such Subscription Plan. After the 30-days term above, no refund can
            be required and, in case of credit card payments, no chargebacks are
            allowed.
          </Paragraphs>
          <Subtitles>
            6.AVAILABILITY, SUPPORT, UPDATES AND MAINTENANCE{" "}
          </Subtitles>
          <Paragraphs>
            <strong>6.1 Availability</strong>. We will use commercially
            reasonable efforts to ensure the availability of the PDF Pro
            Software and the related Services for Our customers. Regardless, You
            acknowledge and agree that We have no control or liability over the
            availability of the PDF Pro Software and the Services on a
            continuous or uninterrupted basis, so that from time to time the
            same may be inaccessible or inoperable, including, without
            limitation, for: (<strong>i</strong>) equipment malfunctions,
            periodic maintenance procedures or repairs; (<strong>i</strong>)
            Force Majeure or any other cause beyond Our reasonable control or
            that are not reasonably foreseeable (including, without limitation,
            threat to security, interruption or failure of telecommunication or
            digital transmission links, hostile network attacks, network
            congestion or other failures); (<strong>iii</strong>) limitations
            due to Your particular device or hardware; (<strong>iv</strong>)
            unavailability, deficiency or incompatibility of any third-party
            software or services provider; (<strong>v</strong>) Your violation
            of any relevant provision of these ToS. Any unavailability of the
            PDF Pro Software and/or the Services under this Sect. 6.1) shall not
            excuse You from Your obligation to make timely payment(s) under the
            applicable Subscription Plan.
          </Paragraphs>
          <Paragraphs>
            <strong>6.2 Technical support</strong>. Technical support, if
            provided by the purchased Subscription Plan, will be provided by Us
            and/or Our partners 9 am to 5 pm UTC+8 (PTH, Philippine Time) Monday
            to Friday. We may use a variety of methods (e.g., in-product,
            internet, chat, e-mail, remote control and phone and online
            meetings) to provide technical support and customer service in
            connection with the PDF Pro Software and the Services. Support may
            be temporarily limited, interrupted or curtailed due to maintenance,
            repair, modifications, upgrades or relocation. In any case, the
            support service shall be considered as part of the Services for all
            the purposes of these ToS.
          </Paragraphs>
          <Paragraphs>
            <strong>6.3 Updates and maintenance</strong>. We, in Our sole
            discretion, reserve the right to add additional tools, utilities,
            improvements, Third Party Software, features or functions, or to
            provide programming fixes, updates and upgrades, to the PDF Pro
            Software and/or to the Services in order to improve and enhance the
            features and performance thereof. In case We provide any of such
            updates and fixes, these ToS shall automatically apply thereto,
            unless We provide other terms along with such updates. Furthermore,
            You acknowledge that the PDF Pro Software and the Services may
            contain automatic update technology in order to provide maintenance
            (such as a bug fix, patches, enhanced functions, missing plug-ins,
            and new versions).
          </Paragraphs>
          <Paragraphs>
            <strong>6.4 Backup</strong>. As We don’t provide any backup or
            restoration service, unless provided in the related Subscription
            Plan, You are encouraged to archive Your Data regularly and
            frequently. Therefore, You shall bear full risk of loss and damage
            of Your Data; We disclaim any liability in such respect.
          </Paragraphs>
          <Subtitles>7.YOUR RESPONSIBILITIES</Subtitles>
          <Paragraphs>
            <strong>7.1 General responsibilities</strong>. You are entirely
            responsible for the use of the PDF Pro Software and the Services
            and, to such purpose, You shall not use, encourage, promote,
            facilitate, instruct (or induce others to do the foregoing on) the
            PDF Pro Software and the Services for any activity that violates any
            applicable Law, or for any other illegal, fraudulent, harmful, or
            offensive purpose, or to transmit, store, display, distribute or
            otherwise make available any Data that is illegal, harmful,
            offensive, libelous, defamatory, obscene, pornographic, profane,
            threatening, abusive, hateful, harassing, inappropriate or that
            would encourage or constitute a criminal or civil liability under
            any Law. Examples of prohibited uses of the PDF Pro Software or the
            Services include: (<strong>i</strong>) creating, uploading,
            modifying and/or distributing any Data, or take any action using the
            PDF Pro Software and/or the Services, that is illegal, fraudulent,
            harmful, or violates any applicable Law; (<strong>ii</strong>)
            creating, uploading, modifying and/or distributing any Data, or take
            any action using the PDF Pro Software and/or the Services, that
            violates any PDF Pro’s right or any third party’s (including,
            without limitations, privacy, Intellectual Property Rights, etc.); (
            <strong>iii</strong>) uploading viruses or malicious codes, or take
            any other action using the PDF Pro Software and/or the Services that
            could in any way compromise any functionality thereof; (
            <strong>iv</strong>) accessing any account belonging to someone else
            or sharing Your account password with any third party; (
            <strong>v</strong>) distributing the PDF Pro Software and/or the
            Services; (<strong>vi</strong>) incorporating the PDF Pro Software
            and/or the Services in any product designed, developed, marketed,
            shared, sold or licensed by You or any third party; (
            <strong>vii</strong>) facilitating or encouraging any violations of
            these ToS.
          </Paragraphs>
          <Paragraphs>
            <strong>7.2 Account security</strong>. You undertake not to share
            Your account login information to any third party or let anyone else
            access the PDF Pro Software and/or the Services (exception made for
            Authorized Users within the limits set out by the applicable
            Subscription Plan), or do anything else that might jeopardize the
            security thereof. You agree to make every reasonable effort to
            prevent unauthorized third parties from accessing the PDF Pro
            Software and/or the Services and agree to notify Us immediately of
            any unauthorized use thereof.
          </Paragraphs>
          <Paragraphs>
            <strong>7.3 Data lawfulness</strong>. You undertake to have all the
            interest and title (including, without limitation, any related
            Intellectual Property Right) in and to the Data that You may
            transmit, store, display, distribute or otherwise make available
            through the Services, and that You are the sole responsible for the
            accuracy, quality, integrity, legality, reliability, and
            appropriateness thereof. Without limiting the foregoing, You warrant
            and represent that such Data is fully compliant with any applicable
            Law. In no way whatsoever We will be responsible for any of such
            Data.
          </Paragraphs>
          <Paragraphs>
            <strong>7.4 Personal Data</strong>. Without limiting the foregoing,
            in case any Data is a Personal Data, You appoint Us as “processor”
            of the said Personal Data in order to process them for the purpose
            of hosting, processing (including, without limitation, reformat,
            manipulate, etc.) at the purpose to provide You with the Services.
            In particular, by accepting these ToS, You: (<strong>i</strong>)
            warrant and represent that the said Personal Data are compliant with
            the applicable Personal Data Protection Laws (including, without
            limitation, Your obligation to use such Personal Data only to the
            limited extent that is necessary and relevant to the Services and
            for no other purpose unless You have obtained the prior express
            consent from the related data subject, etc.); (<strong>ii</strong>)
            accept to be bound also by the PDF Pro Data Processing Agreement
            available at <Link to="/dpa">Data Processing Agreement</Link> ,
            which is an integral part of these ToS and is incorporated herein by
            reference.
          </Paragraphs>
          <Paragraphs>
            <strong>7.5 Export regulations compliance</strong>. You expressly
            acknowledge that You are not an entity targeted by any Law which
            provides economic sanctions, export controls, import regulations or
            trade embargoes, nor You are otherwise owned or controlled by, or
            acting on behalf of, any person or entity targeted or in any way
            affected by the foregoing. Furthermore, You expressly acknowledge
            that You will not download or otherwise export or re-export Our PDF
            Pro Software’s content, products or Services or any related
            technical data directly or indirectly to any person or entity
            targeted by the aforementioned Law or for any end-use prohibited or
            restricted by such Law.
          </Paragraphs>
          <Paragraphs>
            <strong>7.6 Indemnification</strong>. To any third party’s claim
            arising out of a violation of Your obligations under this Section 7
            (Your Responsibilities), Sects. 12.1) and 12.2) below shall apply,
            in addition to any other rights or remedies afforded to Us under
            these ToS and/or as per the applicable Law.
          </Paragraphs>
          <Subtitles>8.TERM AND TERMINATION</Subtitles>
          <Paragraphs>
            <strong>8.1 Term</strong>. These ToS and the rights herein conferred
            are effective as of the Effective Date until the term of the
            applicable Subscription Plan (either yearly or monthly). The
            Subscription Plan shall be tacitly and automatically renewed for
            subsequent terms of the same duration (i.e., either one year or one
            monthly), unless You withdraw by means of a fifteen (15) calendar
            days’ prior written notice, with effects after the expiration of the
            then-current subscription term, original or renewed.{" "}
          </Paragraphs>
          <Paragraphs>
            <strong>8.2 Termination</strong>. In case of Your breach of any term
            herein provided is not cured within 10 (ten) days after Our initial
            notice thereof, We are entitled to terminate these ToS and/or the
            relevant Subscription Plan. In such case, You must pay all amounts
            accrued prior to such termination, as well as all amounts remaining
            unpaid for the Services under such Subscription Plan, plus related
            damages, taxes and expenses.
          </Paragraphs>
          <Paragraphs>
            <strong>8.3 No access after termination</strong>. Upon the
            termination, for any reason whatsoever, of these ToS, You no longer
            have rights to access or use the PDF Pro Software and/or the
            Services and, if applicable, We will delete or otherwise make
            inaccessible Your account and any Data in the PDF Pro Software
            and/or the Services, if any. We shall not be liable towards You, any
            Authorized User or any other third party for the loss of any Data
            and/or customization after the termination of a Subscription Plan
            for whatever reason or cause.
          </Paragraphs>
          <Paragraphs>
            <strong>8.4 Obligations surviving termination</strong>. Provisions
            that survive termination or expiration of these ToS are those
            relating to limitation of liability, indemnification, payment,
            Intellectual Property Rights and others which by their nature are
            intended to survive.
          </Paragraphs>
          <Subtitles>9.PRIVACY AND DATA PROTECTION</Subtitles>
          <Paragraphs>
            <strong>9.1 Security measures and data breach</strong>. We will use
            best efforts to keep Data on the Services secure and confidential by
            implementing appropriate technical and organizational security
            measures to prevent unauthorized access to Data and/or loss or
            corruption thereof. We will promptly notify You in writing if it
            becomes aware that Data has been breached.
          </Paragraphs>
          <Paragraphs>
            <strong>9.2 Privacy Policy</strong>. You declare to have carefully
            read the PDF Pro Privacy Policy (available at{" "}
            <Link to="/privacy">Privacy Policy</Link> ) and got a clear
            understanding of how We collect, use, protect or otherwise handle
            any Personal Data.
          </Paragraphs>
          <Paragraphs>
            <strong>9.3 Right to access Personal Data</strong>. To the maximum
            extent permitted by the applicable Law, We expressly reserves the
            right to access, read, preserve, and disclose any Personal Data as
            it may reasonably believe necessary to: (i) satisfy any applicable
            Law or any authority request; (ii) enforce these ToS (including
            investigation of potential violations thereof) or any PDF Pro’s
            Intellectual Property Rights; (iii) detect, prevent, or otherwise
            address fraud, anti-piracy, security or technical issues (including,
            without limitation, verify a valid registration, the device IP
            address, etc.).
          </Paragraphs>
          <Paragraphs>
            <strong>9.4 Anonymized Data analysis</strong>. We are allowed to
            analyze anonymized Data (i.e., Data that are no more Personal Data
            and that, therefore, do not include personal identifiers of a
            person, such as his or her name, e-mail address, address, etc.) for
            the purpose to maintain, update or improve the PDF Pro Software
            and/or the Services, as well as to conduct internal statistics and
            analytics on the use and proper functioning of the Services. You
            have no right, title or interest in any result of these analyses
            performed by Us, which shall be deemed as Our Intellectual Property
            Rights.
          </Paragraphs>
          <Subtitles>10.INTELLECTUAL PROPERTY RIGHTS</Subtitles>
          <Paragraphs>
            <strong>10.1 PDF Pro’s IP Rights</strong>. Notwithstanding any
            different provision herein, We hold and retain all and any title and
            interest in and to the PDF Pro Software and the Services, as well as
            in and to any Intellectual Property Right associated therewith and,
            therefore, nothing in these ToS will be interpreted as a transfer,
            in whole or in part, of any IP Rights in and to the PDF Pro Software
            and/or in the Services to You, the Authorized Users or any other
            third party.
          </Paragraphs>
          <Paragraphs>
            <strong>
              10.2 Your obligations for Our Intellectual Property Rights
              preservation
            </strong>
            . You shall not, and shall not cause or permit others to: (
            <strong>i</strong>) remove or modify any program markings or any
            notice of Our Intellectual Property Rights or those of its licensors
            on the PDF Pro Software and/or the Services; (<strong>ii</strong>)
            make the materials resulting from PDF Pro Software and/or the
            Services available in any manner to any third party for use in such
            third party’s business operations, unless otherwise provided by the
            purchased Subscription Plan or authorized in written by Us; (i
            <strong>iii</strong>) modify, make derivative works of, disassemble,
            decompile, reverse engineer, reproduce, distribute, republish or
            download any part of the PDF Pro Software and/or the Services, or
            access or use the same in order to build or support, and/or assist a
            third party in building or supporting, products or Software
            competitive to Us; (<strong>iv</strong>) perform or disclose any
            benchmark or performance tests of the PDF Pro Software and/or the
            Services or any associated infrastructure (such as, without
            limitation, network discovery, port and service identification,
            vulnerability scanning, password cracking, remote access testing,
            penetration testing, etc.); and (<strong>v</strong>) license, sell,
            rent, lease, transfer, assign, distribute, host, outsource, permit
            timesharing or service bureau use, or otherwise commercially exploit
            or make available the PDF Pro Software and/or the Services to any
            third party.
          </Paragraphs>
          <Paragraphs>
            <strong>10.3 Your Data</strong>. You expressly grant Us a perpetual,
            worldwide, fully transferable, sub-licensable, irrevocable, fully
            paid-up, royalty free license to perform any operation or set of
            operations (including, without limitation, use, modify, collect,
            record, organize, structure, store, adapt, alter, retrieve, consult,
            use, disclose, combine, reproduce, erase, destroy, etc.) on the Data
            that are not Personal Data that You provide to Us in any way (by
            upload or other mean), as far as necessary for the purposes of
            providing You the Services and improve the functionalities of the
            PDF Pro Software.
          </Paragraphs>
          <Paragraphs>
            <strong>10.4 Your feedback and suggestions</strong>. We may freely
            use Your feedback, suggestions, or ideas in any way, including in
            future modifications of the PDF Pro Software and/or the Services or
            of any other related product, service, advertising or marketing
            material. To such purpose, You warrant Us that You hold the
            Intellectual Property Right in and to any of Your feedback or
            suggestion, and You grant Us a perpetual, worldwide, fully
            transferable, sub-licensable, irrevocable, fully paid-up, royalty
            free license to use the feedback and suggestions provided to Us in
            any way. Notwithstanding the preceding, We will not publish or share
            such feedback in a way that could identify You without Your explicit
            permission.
          </Paragraphs>
          <Paragraphs>
            <strong>10.5 Your name, trademarks or logos</strong>. You grant Us
            the right to mention Your name, trademarks or logos as reference or
            case-study in any of Our websites, presentations, marketing
            activities or materials, or in proposals to be sent to prospective
            clients.
          </Paragraphs>
          <Subtitles>11.WARRANTY AND LIABILITY</Subtitles>
          <Paragraphs>
            <strong>11.1 Limited warranty</strong>. THE PDF PRO SOFTWARE AND THE
            SERVICES ARE PROVIDED “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS”,
            WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY THE
            APPLICABLE LAW, PDF PRO DISCLAIMS ANY AND ALL WARRANTIES, WHETHER
            EXPRESS OR IMPLIED INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            NON-INFRINGEMENT, AND ANY WARRANTY THAT MAY ARISE OUT OF COURSE OF
            DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. TO THE
            FULLEST EXTENT PERMITTED OR AUTHORIZED BY LAW AND WITHOUT LIMITATION
            OF THE FOREGOING, PDF PRO DOES NOT WARRANT THAT THE PDF PRO SOFTWARE
            AND THE SERVICES WILL: MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
            RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE,
            APPLICATIONS, SYSTEMS OR SERVICES, BE AVAILABLE OR OPERATE WITHOUT
            INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR THAT
            THE ACCESS TO THE PDF PRO SOFTWARE AND/OR TO THE SERVICES WILL BE
            UNINTERRUPTED OR ERROR-FREE OR THAT ANY ERRORS OR DEFECTS CAN OR
            WILL BE CORRECTED.{" "}
            <strong>
              THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF
              THESE TOS
            </strong>
            .
          </Paragraphs>
          <Paragraphs>
            <strong>11.2 Disclaimer of liability</strong>. IN NO EVENT PDF PRO
            SHALL BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
            LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, COMPUTER
            FAILURE OR MALFUNCTION, LOSS OF DATA OR PROFITS, BUSINESS
            INTERRUPTION, ETC.) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY,
            WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE
            OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE SERVICES,
            EVEN IF PDF PRO IS AWARE OF OR HAS BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES. IN ANY CASE, PDF PRO IS NOT LIABLE FOR THE ACTS OR
            OMISSIONS OF ITS CONTRACTORS, VENDORS, CLOUD PROVIDER OR OTHER
            SERVICE PROVIDER.{" "}
            <strong>
              THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF
              THESE TOS
            </strong>
            .
          </Paragraphs>
          <Paragraphs>
            <strong>11.3 Remedies</strong>. EXCEPT FOR CLAIMS WITH RESPECT TO
            FRAUD, DEATH OR PERSONAL INJURY, FOR WHICH NO LIMIT SHALL APPLY, IN
            NO EVENT – TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW –
            PDF PRO’S LIABILITY SHALL IN THE AGGREGATE EXCEED THE FEES
            EFFECTIVELY PAID BY YOU FOR THE RELEVANT SUBSCRIPTION PLAN, DURING
            THE TWELVE (12) MONTHS PERIOD PRECEDING THE DATE ON WHICH THE CLAIM
            AROSE.{" "}
            <strong>
              THE FOREGOING CONSTITUTES YOUR SOLE REMEDIES AVAILABLE TO YOU WITH
              RESPECT TO ANY OF OUR LIABILITY UNDER THESE TOS
            </strong>
            .
          </Paragraphs>
          <Paragraphs>
            <strong>11.4 Mandatory legal provisions</strong>. Some jurisdictions
            do not allow certain limitations of warranty or liability, therefore
            some or all of the limitations above provided may not apply to You.
            In such case, the limitations provided herein shall be applicable to
            the fullest and maximum extent permitted by the applicable Law.{" "}
          </Paragraphs>
          <Subtitles>12.INDEMNIFICATION</Subtitles>
          <Paragraphs>
            <strong>12.1 Indemnification</strong>. You shall indemnify and hold
            Us harmless from and against all damages, losses, and expenses of
            any kind (including reasonable legal fees and costs) arising by any
            claim made by any third party (including, without limitation, any
            agency, public administrations, entities, etc.) against Us in
            connection with any of Your and/or Your Authorized Users’: (
            <strong>i</strong>) violation or breach of any term of these ToS; (
            <strong>ii</strong>) violation of any applicable Law, whether or not
            referenced herein; (<strong>iii</strong>) violation of any rights of
            any third party; or (<strong>iv</strong>) use or misuse of PDF Pro
            Software and/or the Services.
          </Paragraphs>
          <Paragraphs>
            <strong>12.2 Remedies</strong>. You expressly agree and acknowledge
            that any violation of Your obligations pursuant to these ToS may
            cause Us irreparable harm and damage, which may not be recovered at
            Law. Therefore, You agree that Our remedies for breach of these ToS
            may be in equity by way of injunctive relief, as well and any other
            relief available, whether in Law or in equity.
          </Paragraphs>
          <Subtitles>13.MISCELLANEOUS</Subtitles>
          <Paragraphs>
            <strong>13.1 Assignment</strong>. You are not allowed to assign
            these ToS or any rights hereunder, unless with Our previous written
            consent. Conversely, We are allowed, at Our sole discretion, to
            assign these ToS or any rights hereunder to any third party, without
            giving You any prior notice.
          </Paragraphs>
          <Paragraphs>
            <strong>13.2 Force majeure</strong>. A Party shall not be
            responsible for any failure to perform due to any event of Force
            Majeure. In the event of any such delay, the affected Party will be
            excused from such performance to the extent it is delayed or
            prevented by such cause. However, the other Party may terminate
            these ToS forthwith on written notice if such Force Majeure
            condition continues for a period of sixty (60) days.
          </Paragraphs>
          <Paragraphs>
            <strong>13.3 No implied waiver</strong>. Any tolerance or silence by
            Us, even if on a continuous and reiterated basis, in relation to any
            breach or default by You of any provision of these ToS shall not be
            considered as a consent to such breaches and defaults and shall not
            affect the validity of the clause breached nor shall be construed as
            a waiver to any of the rights pertaining to Us pursuant to these ToS
            and/or the applicable Law.
          </Paragraphs>
          <Paragraphs>
            <strong>13.4 Governing law and exclusive jurisdiction</strong>.
            These ToS are governed by the substantive and procedural Laws of
            British Columbia, Canada, without application of the “United Nations
            Convention on Contracts for the International Sale of Goods”. Any
            dispute arising out of, or relating to, these ToS shall be submitted
            to the exclusive jurisdiction of the Court of Vancouver (Canada).
          </Paragraphs>
          <Paragraphs>
            <strong>13.5 Prevailing language</strong>. Any translation of these
            ToS is provided solely for your convenience and is not intended to
            modify the terms herein. In the event of a conflict between the
            English version of these ToS and a version in a language other than
            English, the English version shall control and prevail.
          </Paragraphs>
          <Paragraphs>
            <strong>13.6 Severability</strong>. Should any part of these ToS be
            declared illegal or unenforceable, the remaining portion will remain
            in full force and effects.
          </Paragraphs>
          <Paragraphs>
            <strong>13.7 Amendments</strong>. The effective date of the last
            version of these ToS is written above, as published from time to
            time on Our website at <Link to="/terms">Terms of Service</Link>. We
            have the right to amend these ToS at any time, and to change,
            delete, discontinue or impose conditions on use of the PDF Pro
            Software and/or the Services, in which case the new terms and
            conditions will supersede prior terms. Any changes will be effective
            after thirty (30) days from the time We first notify You about such
            changes via email or/and notifications while using the PDF Pro
            Software, the Services and/or other reasonable means. Your continued
            use of the PDF Pro Software and/or the Services following changes to
            these ToS after the effective date of a revised version thereof
            constitutes Your expressed acceptance of, and agreement to be bound
            by, these ToS as in force from time to time. If You do not accept
            modified ToS in part or full, You must terminate Your account and
            stop using the PDF Pro Software and the Services before the change
            takes effect.
          </Paragraphs>
          <Paragraphs>
            <strong>13.8 Entire agreement</strong>. These ToS, along with the
            terms and conditions incorporated herein by reference (i.e., the PDF
            Pro EULA (available at <Link to="/licensing">EULA</Link>), the PDF
            Pro Privacy Policy (available at{" "}
            <Link to="/privacy">Privacy Policy</Link>) and the PDF Pro Data
            Processing Addendum (available at{" "}
            <Link to="/dpa">Data Proccessing Agreement</Link> ) constitute the
            entire agreement between the Parties with respect to its subject
            matter and it supersedes all prior or contemporaneous agreements
            concerning such matter.
          </Paragraphs>
        </Text>
      </Container>
      <NewFooter></NewFooter>
    </>
  )
}

export default CheckoutPage
